import { RouteEnum } from 'enums/RouteEnum'

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    id: 0,
    src: '/images/megaMenu/domainSales.png',
    head: 'Domain Sales Lookup',
    content:
      'Our rich domain sales history will give you an idea of the most sought domain names. ',
    href: RouteEnum.TOOLS_DOMAIN_SALES_LOOKUP,
  },
  {
    id: 1,
    src: '/images/megaMenu/domainMonitor.png',
    head: 'Domain Monitor',
    content:
      'Track all the changes on a certain domain and get real-time alerts. ',
    href: RouteEnum.TOOLS_DOMAIN_MONITOR,
  },
  // {
  //   id: 2,
  //   src: '/images/megaMenu/domainLookup.png',
  //   head: 'Domain Backlink Lookup',
  //   content:
  //     'All the backlink information for all domains is at your fingertips.',
  //   href: RouteEnum.TOOLS_BACKLINKS_LOOKUP,
  // },
  {
    id: 2,
    src: '/images/megaMenu/brand.png',
    head: 'Brand Monitor',
    content:
      'Use a keyword to track all your brand mentions — positive or negative.',
    href: RouteEnum.TOOLS_BRAND_MONITOR,
  },
  {
    id: 3,
    src: '/images/megaMenu/seoMetrics.png',
    head: 'Track SEO Metrics',
    content:
      'Track all your SEO metrics and know when to maintain, change, or improve your SEO tactics.',
    href: RouteEnum.TOOLS_SEO_METRICS,
  },
  {
    id: 4,
    src: '/images/megaMenu/domainSeo.png',
    head: 'Domain SEO Analysis',
    content:
      'Choose the right domain after a comprehensive optimization audit.',
    href:  RouteEnum.TOOLS_SEO_ANALYSIS,
  },
]

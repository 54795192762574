import { RouteEnum } from 'enums/RouteEnum'

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    id: 0,
    src: '/images/megaMenu/freshdrops.png',
    head: 'Fresh Drops',
    content:
      'Hand-picked domains available to register immediately. Fresh and on daily basis.',
    href: RouteEnum.TOOLS_FRESH_DROPS,
  },
  {
    id: 1,
    src: '/images/megaMenu/domain.png',
    head: 'Domain Availability Lookup',
    content:
      'Search the availability of single or multiple domains in a click.',
    href: RouteEnum.TOOLS_AVAILABILITY_LOOKUP,
  },
  {
    id: 2,
    src: '/images/megaMenu/domainAge.png',
    head: 'Domain Age Checker',
    content:
      'This is the tool you need to check the age of a domain. Get feedback in seconds. ',
    href: RouteEnum.TOOLS_AGE_LOOKUP,
  },
  {
    id: 3,
    src: '/images/megaMenu/enhancedDomain.png',
    head: 'Domain WHOIS Lookup',
    content:
      'Instantly check all the whois information available for a domain.',
    href: RouteEnum.TOOLS_WHOIS_LOOKUP,
  },
  {
    id: 4,
    src: '/images/megaMenu/searchAll.png',
    head: 'Search All Registered Domains',
    content:
      'Search all the registered domains in the world. Over 350 million domains.',
    href: RouteEnum.TOOLS_SEARCH_ALL_REGISTERED_DOMAINS,
  },
  {
    id: 5,
    src: '/images/megaMenu/domain-trends.png',
    head: 'Domain Trends',
    content:
      'Track all the latest trends in your industry. All you need is to add the relevant keyword.',
    href: RouteEnum.DOMAIN_TRENDS,
  },
]
